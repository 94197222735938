<template>
  <div class="flex space-x-2">
    <button
      v-for="tab in tabs"
      :key="tab.id"
      @click="selectTab(tab.id)"
      class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
      :class="{
        'bg-orange-300 text-orange-900': selectedTab === tab.id,
        'hover:bg-gray-200': selectedTab !== tab.id,
      }"
    >
      <Icon :iconType="tab.iconType" v-if="tab.iconType" class="mr-1" />
      <span>{{ tab.label }}</span>
    </button>
  </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
  },
  methods: {
    selectTab(tabId) {
      this.$emit("tab-selected", tabId);
    },
  },
};
</script>
