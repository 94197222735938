<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Attendance" backTo="internal">
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <!-- Toolbar with Search Controls -->
        <div class="w-full flex items-center justify-between">
          <div class="flex -mx-0 mr-2">
            <TabHeaders :tabs="tabs" :selectedTab="currentTab" @tab-selected="onTabSelected"/>
          </div>
          <div class="flex flex-col flex-1">
            <div class="flex justify-end">
              <div class="flex items-center mr-4">
                <label class="mr-2">From Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="fromDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                    :maxDate="maxDate"
                />
              </div>
              <div class="flex items-center ">
                <label class="mr-2">To Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="toDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                    :maxDate="maxDate"
                />
              </div>


              <input
                  type="text"
                  class="ml-6 w-full lg:w-auto rounded-l bge-input bge-input-spacing"
                  placeholder="Search"
                  v-model="searchTextInput"
                  @keydown.enter="triggerSearch"
              />
              <button
                  @click="triggerSearch"
                  class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
              >
                <Icon iconType="search"/>
              </button>
            </div>
          </div>
        </div>

        <!-- Attendance Table -->
        <DataTableComponent :columns="attendanceColumns" :rowGroupMode="rowGroupMode" :groupRowsBy="groupRowsBy" :rowClass="rowClass" :enableRowClick=false>

          <template v-slot:name-slot="{ rowData }">
            {{ rowData.first_name }} {{ rowData.last_name }}
          </template>

          <template v-slot:date-slot="{ rowData, colKey }">
            <div v-if="rowData[colKey]" :class="getCardBgClass(rowData[colKey].duration) + ' border border-gray-300 shadow-sm rounded-lg p-4 mb-2 mt-2 bg-white'">
              <div class="flex justify-between mb-2">
                <div class="text-gray-600">In:</div>
                <div class="font-semibold text-black">{{ rowData[colKey].in_time || 'N/A' }}</div>
              </div>
              <div class="flex justify-between mb-2">
                <div class="text-gray-600">Out:</div>
                <div class="font-semibold text-black">{{ rowData[colKey].out_time || 'N/A' }}</div>
              </div>
              <div class="flex justify-between">
                <div class="text-gray-600">Total:</div>
                <div class="font-semibold text-black">{{ rowData[colKey].duration || 'N/A' }}</div>
              </div>
            </div>
            <div v-else class="border border-gray-300 shadow-sm rounded-lg p-4 mb-4 bg-white text-center text-gray-500">
              N/A
            </div>
          </template>
        </DataTableComponent>


      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Calendar from "primevue/calendar";
import Icon from "@/components/Icon.vue";
import DataTableComponent from "@/views/HR/Attendance/Components/DataTableComponent.vue";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import TabHeaders from "@/components/TabHeaders.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: {
    TabHeaders,
    PageHeader,
    Calendar,
    Icon,
    DataTableComponent
  },
  data() {
    return {
      tabs: [
        {id: "staff", label: "Staff",},
        {id: "guest", label: "Guest",},
      ],
      rowGroupMode: null,
      groupRowsBy: null,
      rowClass: null,
      maxDate: new Date(),
    };
  },
  computed: {
    ...mapState("attendance", {
      currentTabState: state => state.tabs[state.currentTab],
      isLoading: state => state.isLoading,
      currentTab: state => state.currentTab,
      searchText: state => state.searchText,
      fromDate: state => state.fromDate,
      toDate: state => state.toDate,
    }),
    searchTextInput: {
      get() {
        return this.searchText;
      },
      set(value) {
        this.$store.commit("attendance/UPDATE_SEARCH_TEXT", value);
      },
    },

    fromDateInput: {
      get() {
        const fromDate = this.fromDate;
        return fromDate ? new Date(fromDate) : null;
      },
      set(value) {
        this.$store.commit("attendance/UPDATE_FROM_DATE", value);
      },
    },

    toDateInput: {
      get() {
        const toDate = this.toDate;
        return toDate ? new Date(toDate) : null;
      },
      set(value) {
        this.$store.commit("attendance/UPDATE_TO_DATE", value);
      },
    },
    attendanceColumns() {
      if (this.currentTabState.tableData.length > 0) {
        // Extract and sort the attendance dates in descending order
        const sortedDates = Object.keys(this.currentTabState.tableData[0])
            .filter(key => !["id", "first_name", "last_name"].includes(key))
            .map(date => ({
              field: date,
              unix_date: moment(date, "MMM D ddd").unix(),
            }))
            .sort((a, b) => b.unix_date - a.unix_date);

        return [
          {
            field: "first_name",
            header: "Name",
            sortable: true,
            custom: true,
            slotName: "name-slot",
            width: '10%',
          },
          ...sortedDates.map(({ field }) => ({
            field,
            header: field,
            custom: true,
            slotName: "date-slot",
            width: '10%',
            headerClass: 'column-h-text-center'
          }))
        ];
      }
      return [];
    }

  },
  mounted() {
    this.fetchTableData();
  },
  methods: {
    ...mapActions("attendance", ["fetchTableData", "updateSearchCriteria", "updateTableSettings", "updateSortOptions","updateTab","resetCurrentPage"]),

    validateDates() {
      const hasFromDate = !!this.fromDateInput;
      const hasToDate = !!this.toDateInput;

      if (hasFromDate && hasToDate) {
        if (this.toDateInput < this.fromDateInput) {
          this.notifyWarning("To Date should not be earlier than From Date.");
          return false;
        }
        const fromDate = moment(this.fromDateInput);
        const toDate = moment(this.toDateInput);
        const daysDifference = toDate.diff(fromDate, 'days');
        if (daysDifference > 6) {
          this.notifyWarning("The date range should not be more than seven days.");
          return false;
        }
        return true;
      }

      if (hasFromDate !== hasToDate) {
        this.notifyWarning("Please select both the From Date and To Date.");
        return false;
      }

      return true;
    },

    onTabSelected(newTab) {
      this.updateTab(newTab);
    },

    triggerSearch() {
      if (this.validateDates()) {
        this.resetCurrentPage(0);
        this.updateTab(this.currentTab);
      }
    },
    getCardBgClass(duration) {
      if (!duration) return 'bg-gray-100';
      const [hours] = duration.split('h').map(part => parseInt(part));
      if (hours > 8) {
        return 'bg-green-100';
      } else if (hours >= 7) {
        return 'bg-yellow-100';
      } else {
        return 'bg-red-100';
      }
    },
  },
};
</script>

<style scoped>
.table {
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.5rem;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f9f9f9;
}

.bg-green-200 {
  background-color: #c6efce;
}

.bg-red-200 {
  background-color: #ffc7ce;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

</style>
